<template>
  <router-view v-slot="{ Component }">
    <keep-alive include="MyHome,CreatOrder,Catgrory">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
</style>

