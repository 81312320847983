import { createRouter, createWebHashHistory } from "vue-router";
import { Toast } from 'vant'
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '',
            redirect: '/home',
            meta: {
                isAuth: false,
            }
        },
        {
            path: '/home',
            name: 'home',
            component: () => import("@/pages/myHome/MyHome"),

            meta: {
                isAuth: false,
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import("@/pages/login/Login"),
            meta: {
                isAuth: false,


            }
        },
        {
            path: '/register',
            name: 'register',
            component: () => import("@/pages/register/Register"),
            meta: {
                isAuth: false,

            }
        },
        {
            path: '/mine',
            name: 'mine',
            component: () => import("@/pages/mine/Mine"),
            meta: {
                isAuth: true,
            },
        },
        {
            path: '/catgrory',
            name: 'catgrory',
            component: () => import("@/pages/catgrory/Catgrory"),
            meta: {
                isAuth: false,

            }
        },
        {
            path: '/cart',
            name: 'cart',
            component: () => import("@/pages/cart/Cart"),
            meta: {
                isAuth: true,

            }
        },
        {
            path: '/goodDetails',
            name: 'goodDetails',
            component: () => import("@/pages/goodDetail/GoodDetails"),
            meta: {
                isAuth: false,

            }
        },
        {
            path: '/creatOrder',
            name: 'creatOrder',
            component: () => import("@/pages/creatOrder/CreatOrder"),
            meta: {
                isAuth: true,

            }
        },
        {
            path: '/addressList',
            name: 'addressList',
            component: () => import("@/pages/address/AddressList"),
            meta: {
                isAuth: true,

            }
        },
        {
            path: '/addEditAddress',
            name: 'addEditAddress',
            component: () => import("@/pages/address/AddEditAddress"),
            meta: {
                isAuth: true,
            }
        }, {
            path: '/demotest',
            name: 'demotest',
            component: () => import("@/pages/cart/demo"),
            meta: {
                isAuth: false,
            }
        }, {
            path: '/catGoodList',
            name: 'catGoodList',
            component: () => import("@/pages/catgrory/CatGoodList"),
            meta: {
                isAuth: false,
            }
        }, {
            path: '/searchIndex',
            name: 'searchIndex',
            component: () => import("@/pages/search/SearchIndex"),
            meta: {
                isAuth: false,
            }
        }, {
            path: '/orderList',
            name: 'orderList',
            component: () => import("@/pages/order/OrderList"),
            meta: {
                isAuth: false,
            }
        }, {
            path: '/searchResult',
            name: 'searchResult',
            component: () => import("@/pages/search/SearchResultList"),
            meta: {
                isAuth: false,
            }
        },
        {
            path: '/mine/setting',
            name: 'setting',
            component: () => import('@/pages/mine/Setting.vue'),
            meta: {
                isAuth: true,
            }
        }, {
            path: '/mine/changeLoginPwd',
            name: 'changeLoginPwd',
            component: () => import('@/pages/mine/ChangeLoginPwd.vue'),
            meta: {
                isAuth: true,
            }
        }, {
            path: '/mine/changePayPwd',
            name: 'changePayPwd',
            component: () => import('@/pages/mine/ChangePayPwd.vue'),
            meta: {
                isAuth: true,
            }
        }, {
            path: '/mine/changeNickName',
            name: 'changeNickName',
            component: () => import('@/pages/mine/ChangeNickName.vue'),
            meta: {
                isAuth: true,
            }
        }



    ]
})


router.beforeEach((to, from, next) => {
    if (to.meta.isAuth) {
        if (localStorage.getItem("isLogin") === "1") {
            next();
        } else {
            Toast('请先登录');
            router.push('./login');
        }
    } else {
        next();
        // 
    }
    // window.scrollTo(0, 0);
})
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})


export default router