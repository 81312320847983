import { createApp } from 'vue'
import App from './App.vue'
import 'amfe-flexible'

import router from './router/index';
import store from './store/index';


import {
    Button, Form, Field, CellGroup, Checkbox,
    CheckboxGroup, Tabbar, TabbarItem, PullRefresh, Search, Swipe, SwipeItem,
    NoticeBar, List, Sidebar, SidebarItem, Badge, Divider, ActionBar, ActionBarIcon,
    ActionBarButton, Popup, Stepper, Dialog, ContactCard, AddressList, AddressEdit,
    Uploader, PasswordInput, NumberKeyboard, Overlay, NavBar, Tab, Tabs, ActionSheet
} from 'vant';

import { Icon } from 'vant';

import './common/css/base.less';


const app = createApp(App)

app.use(Button).use(Form).use(Field).use(CellGroup).use(Checkbox).use(CheckboxGroup)
    .use(Tabbar).use(TabbarItem).use(PullRefresh).use(Search).use(Swipe).use(SwipeItem)
    .use(NoticeBar).use(List).use(Sidebar).use(SidebarItem).use(Badge).use(Divider)
    .use(ActionBar).use(ActionBarIcon).use(ActionBarButton).use(Popup).use(Stepper)
    .use(Dialog).use(ContactCard).use(AddressList).use(AddressEdit).use(Uploader).use(PasswordInput)
    .use(NumberKeyboard).use(Overlay).use(NavBar).use(Tab).use(Tabs).use(ActionSheet);

app.use(router);
app.use(Icon);
app.use(store);



app.mount('#app')