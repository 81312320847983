import { createStore } from 'vuex';

export default createStore({
    state: {
        isLogin: localStorage.getItem("isLogin"),
        token: localStorage.getItem("token"),
        mm: 1,
        cartList: [], //购物车列表数据
        goodList: [],
        edit: true,
        orderList: [],
        userAddress: [],
        searchList: localStorage.getItem("searchResult") == null ? [] : localStorage.getItem("searchResult").split(","),
    },
    mutations: {
        ADDCART(state, value) {
            state.cartList = value;
        },

        ADDORDER(state, value) {
            state.orderList = value;
        },


        SAVEGOODLIST(state, value) {
            state.goodList = state.goodList.concat(value);
        },

        //编辑的按钮
        EDIT(state, value) {
            if (value === 'delete' || value === 'init') {
                state.edit = true;
            } else {
                state.edit = !state.edit;
            }
        },


        //新增地址
        ADDADDRESS(state, value) {

            state.userAddress.map((item) => {
                if (value.isDefault) {
                    item.isDefault = false;
                }
            });
            if (state.userAddress.length === 0) {
                value.isDefault = true;
            }
            state.userAddress.push(value);
        },
        //编辑地址
        CHANGEADDRESS(state, value) {
            state.userAddress = state.userAddress.map((item) => {
                if (value.isDefault) {
                    item.isDefault = false;
                }
                return item.id === value.id ? value : item;
            });
        },
        //地址删除按钮
        DELETEADDRESS(state, value) {
            state.userAddress = state.userAddress.filter((item) => {
                return !(value.id === item.id);
            });
            if (value.isDefault && state.userAddress.length) {
                state.userAddress[0].isDefault = true;
            }
        },

        ADDSEARCHRECORD(state, value) {
            if (value.includes(",")) {
                value = value.replace(/,/g, '');
            }

            if (state.searchList != "") {
                // state.searchList = state.searchList.split(',');
                // state.searchList = JSON.parse(state.searchList)
            }
            if (state.searchList.includes(value)) { return }
            state.searchList.unshift(value);

            if (state.searchList.length > 5) {
                state.searchList.pop();
            }

            localStorage.setItem("searchResult", state.searchList)
        },


        //清空搜索记录
        CLEARRECORD(state) {
            state.searchList.splice(0, state.searchList.length);
            localStorage.removeItem("searchResult");
        }



    },
    actions: {

    },
    getters: {



    }
});